import { Link } from "gatsby"
import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Logo from "../components/image/logo"

const Header = () => (
  <header className="sticky top-0 bg-white z-50 h-20 flex items-center justify-center">
    <div className="mt-8 md:mt-auto container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8 font-semibold bg-white text-xs md:text-base">
      <div className="w-48">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
        <Link to="/about">
          <div className="text-black md:px-2">Tentang Kami</div>
        </Link>
        <AnchorLink offset={() => 100} href="#contact" className="px-4 text-black">
          Kontak
        </AnchorLink>
        <Link to="/finalist">
          <div className="text-black px-4">Finalis</div>
        </Link>
      </div>
    </div>
  </header>
)

export default Header
